import React from 'react';
import get from 'lodash/get';

const buildProjectItems = projects => {
    const items = [];
    projects.forEach((project, index) => {
        items.push(
            <div className="project">
                <span className="label" title={project.name}>
                    {project.name}
                </span>
                <span className="value">{project.totalCost.toFixed(2)}</span>
            </div>
        );
    });
    return items;
};

const PaymentSummary = ({ invoice }) => {
    const projects = get(invoice, 'projectSummary', []);
    return (
        <div className="summary payment-summary row">
            <div className="col-xl-4 col-md-1" />
            <div className="col-xl-8 col-md-10">
                {projects.length > 1 && (
                    <React.Fragment>
                        <div className="projects">
                            <span className="label">Projects:</span>
                            <span className="value"></span>
                        </div>
                        <div className="projects">
                            {buildProjectItems(projects)}
                        </div>
                        <hr className="line-1" />
                    </React.Fragment>
                )}
                <div className="cost">
                    <span className="label">Translation cost</span>
                    <span className="value bold-2">
                        {get(invoice, 'netCost', 0).toFixed(2)}
                    </span>
                </div>
                <div className="free">
                    <span className="label">Credit card processing fee</span>
                    <span className="value bold-2">
                        {get(invoice, 'totalTransactionFee', 0).toFixed(2)}
                    </span>
                </div>
                <div className="vat">
                    <span className="label">
                        {`VAT (${get(invoice, 'currency', '')})`}
                    </span>
                    <span className="value">
                        {get(invoice, 'totalVAT', 0).toFixed(2)}
                    </span>
                </div>
                <hr className="line-2" />
                <div className="total-payed">
                    <span className="label bold-1">
                        {`Total to pay (${get(invoice, 'currency', '')})`}
                    </span>
                    <span className="value bold">
                        {get(invoice, 'totalCost', 0).toFixed(2)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentSummary;
