import React from 'react';
import get from 'lodash/get';

import './LangSummaryTable-style.css';

const LangSummaryTable = ({ data }) => {
    const buildLangItems = langDataRows => {
        const items = [];

        langDataRows &&
            langDataRows.forEach((data, index) => {
                items.push(
                    <li
                        key={`lang-sum-item-${index}`}
                        className="lang-summary-item"
                    >
                        <div className="cell name" title={data.name}>
                            {data.name}
                        </div>
                        <div className="cell count">{data.wordCount}</div>
                        <div className="cell cost-per-word">
                            {get(data, 'costPerWord', 0).toFixed(2)}
                        </div>
                        <div className="cell discount">
                            {data.discount > 0 ? `${data.discount} %` : ''}
                        </div>
                        <div className="cell total-cost">
                            {get(data, 'totalCost', 0).toFixed(2)}
                        </div>
                    </li>
                );
            });

        return items;
    };

    const buildProjectItems = projects => {
        const items = [];

        projects &&
            projects.forEach((project, index) => {
                items.push(
                    <div
                        key={project.name + index}
                        className="content-container box"
                    >
                        <div className="project-info-wrapper">
                            <span className="label">Project:</span>
                            <span>{' ' + project.name}</span>
                        </div>
                        <div className="lang-summary-table">
                            <div className="header">
                                <div className="cell name">Language</div>
                                <div className="cell count">Items</div>
                                <div className="cell cost-per-word">
                                    Per word
                                </div>
                                <div className="cell discount">Discount</div>
                                <div className="cell total-cost">Cost</div>
                            </div>
                            <ul className="body">
                                {buildLangItems(project.rows)}
                            </ul>
                        </div>
                    </div>
                );
            });

        return items;
    };

    return (
        <div className="summary order-summary">
            <div className="title">Order summary</div>
            {buildProjectItems(data)}
        </div>
    );
};

export default LangSummaryTable;
